<template>
  <tr class="quotation-list-item">
    <td class="is-active"> 
      <input type="checkbox" :checked="quotation.isActive" @change="setSelectedQuoteEvent($event)" />
    </td>
    <td>{{ quotation.id }}</td>
    <td>{{ quotation.date }}</td>
    <td>{{ quotation.expirationDate }}</td>
    <td class="name">{{ quotation.name }}</td>
    <td>{{ formattedPrice(quotation.subtotalExclVat) }}</td>
    <td class="action-btns">
      <i class="fa fa-print" @click="printQuotation()" :title="printTitle"></i>
      <a class="btn-u btn-outline" :href="detailsUrl" v-translation="{ code: 'button_resume_order', type: 'button'}"></a>
      <i class="fa fa-trash-o delete-button" @click="deleteQuotation()"></i>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from 'src/utilities/helpers';
export default {
  props: {
    quotation: { type: Object, required: true }
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters(['language']),
    printTitle() {
      return window.vue.translations.button.button_print_quote
    },
    detailsUrl() {
      var detailsUrl = new URL(location.origin + "/" + this.language + "/bespoke/account/quotationdetails.aspx");
      detailsUrl.searchParams.append('quoteid', this.quotation.id);
      return detailsUrl;
    }
  },
  methods: {
    ...mapActions('quotations', ['setSelectedQuote']),
    formattedPrice (price) {
      return formatPrice(price);
    },
    printQuotation() {
      this.$emit('printQuotationEvent', this.quotation.id);
    },
    deleteQuotation() {
      this.$emit('deleteQuotationEvent', this.quotation.id);
    },
    setSelectedQuoteEvent(ev) {
      this.setSelectedQuote({ quoteId: this.quotation.id, isActive: ev.target.checked });
    }
  }
}
</script>

<style>
</style>